import ArticlesTableHeaders from "./ArticlesTable/ArticlesTableHeaders/ArticlesTableHeaders";
import ArticlesTableItem from "./ArticlesTable/ArticlesTableItem/ArticlesTableItem";

import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable'
// import DeleteConfirmation from '../Cards/DeleteConfirmation/DeleteConfirmation'

import AmountSelect from '../AmountSelect/AmountSelect';
import Pagination from '../Pagination/Pagination';

// import FailAnimation from '../FailAnimation/FailAnimation'


// css classes
import classes from '../Creatives/Creatives.module.scss';

// icons 
import add_white from '../../assets/icons/add_white.svg'

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import {Link } from 'react-router-dom'
// import { clearAllTimeouts, fullYearParser, hoursMinutesParser, TimeOutHandler} from '../../utils/helper_functions';
// import CheckAnimation from "../CheckAnimation/CheckAnimation";






const Article = () => {
    const API_URL = process.env.REACT_APP_API_URL
    const api_token = useSelector(state => state.user.token)

    const names = useSelector(state => state.ui.language.values.articlesContent)
    const article_table_headers = names.article_table_headers

    // // component states
    // const [showInstructionForm, setShowInstructionForm] = useState(false)
    // const [editTargetArticleInstruction, setEditTargetArticleInstruction] = useState(false)
    // const [targetArticleInsObj, setTargetArticleInsObj] = useState([])
    // const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    
    

    // // data states
    const [articlesData, setArticlesData] = useState([])

    // const [targetDomain, setTargetDomain] = useState('')



    // // animation section
    const [loadingResults, setLoadingResults] = useState(false)
    // const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    // const [showFailAnimation, setShowFailAnimation] = useState(false)

    // // alert section
    // const [showFormAlert, setShowFormAlert] = useState(false)
    // const [showInstructionGetErrorMsg, setShowInstructionGetErrorMsg] = useState(false)
    // const [showInstructionDeleteSuccessMsg, setShowInstructionDeleteSuccessMsg] = useState(false)
    // const [showInstructionDeleteFailMsg, setShowInstructionDeleteFailMsg] = useState(false)
    

    // select one or multiple or all
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [checkedNumber, setCheckedNumber] = useState(0)


    // pagination section
    const [pageNumber, setPageNumber] = useState(1)
    const [numberOfResults, setNumberOfResults] = useState(24)
    const [numberOfResultsAfterQuery, setNumberOfResultsAfterQuery] = useState(0)
    const [numberOfPages, setNumberOfPages] = useState(1)
    const [numberOfTotalEntries, setNumberOfTotalEntries] = useState(0)



    // // useeffect section 

    // const getinstructions = async () => {
    //     setLoadingResults(true)
    //     console.log("\n\nGetting instructions from backend :", targetDomain, pageNumber, numberOfResults)    
    //     const response = await fetch(`${API_URL}/api/v1/getarticleinstruction`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${api_token}`
    //         },
    //         body: JSON.stringify({
    //             page: pageNumber,
    //             items: numberOfResults,
    //         })
    //     })

    //     const res_status = response.status
    //     const data = await response.json()

    //     if(res_status === 200) {
    //         const total_entires = data.total_entires
    //         setNumberOfTotalEntries(total_entires)
    //         setArticleInstructionsData(data.res)
    //         setNumberOfResultsAfterQuery(data.res_len)

    //         if(numberOfResults > total_entires){
    //             setNumberOfPages(1)
    //         }else{
    //             setNumberOfPages(Math.ceil(total_entires/numberOfResults))
    //         }
    //     }
    //     else{
    //         console.log("=== Error in get instructions ===> ", res_status, data.success, data.error)
    //         setShowInstructionGetErrorMsg(true)
    //         setShowFailAnimation(true)
    //         TimeOutHandler(()=>{
    //             setShowInstructionGetErrorMsg(false)
    //         }, 4000)
    //         TimeOutHandler(()=>{
    //             setShowFailAnimation(false)
    //         }, 1500)
    //     }
    //     setLoadingResults(false)
    // }


    // // initial data fetch when components are rendered
    // useEffect(()=>{
    //     getinstructions()
    // }, [])

    const handleSelectAll = e => {       
        setIsCheckAll(!isCheckAll);
        setIsCheck(articlesData.map((item, index) => index));
        if (isCheckAll) {
            setIsCheck([]);
        }
        console.log("======================>>>> :", isCheckAll, isCheck, checkedNumber)
    };


    // const handleClick = (e) => {
    //     const { id, checked } = e.target;
    //     setIsCheck([...isCheck, parseInt(id)]);
        
    //     if(!checked) {
    //         setIsCheck(isCheck.filter(item => item !== parseInt(id)));
    //     }
    // };



    // const showTargetInstructionBlog = () => {

    // }

    // const handleUpdateInstruction = () => {
        
    // }



    // const handleDeleteInsOperation = async () => {
    //     setLoadingResults(true)
    //     setShowDeleteConfirmation(false)

    //     console.log("\n\nDeleting instructions from backend :", targetArticleInsObj)    
    //     const response = await fetch(`${API_URL}/api/v1/articleinstruction`, {
    //         method: 'DELETE',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${api_token}`
    //         },
    //         body: JSON.stringify({
    //             id: targetArticleInsObj['id'],
    //         })
    //     })

    //     const res_status = response.status
    //     const data = await response.json()

    //     setLoadingResults(false)
    //     if(res_status === 200) {
    //         setShowInstructionDeleteSuccessMsg(true)
    //         setShowCheckAnimation(true)
    //         TimeOutHandler(()=>{
    //             setShowCheckAnimation(false)
    //             getinstructions()                
    //         }, 1500)
    //         TimeOutHandler(()=>{
    //             setShowInstructionDeleteSuccessMsg(false)
    //         }, 4000)
    //     }
    //     else{
    //         console.log("=== Error in get instructions ===> ", res_status, data.success, data.error)
    //         setShowInstructionDeleteFailMsg(true)
    //         setShowFailAnimation(true)

    //         TimeOutHandler(()=>{
    //             setShowFailAnimation(false)
    //         }, 1500)
    //         TimeOutHandler(()=>{
    //             setShowInstructionDeleteFailMsg(false)
    //         }, 4000)
    //     }

    //     // going back to initial states of targetArticleInsObj
    //     setTargetArticleInsObj([])
    // }


    // const showDeleteInstructionPopUp = (e) => {
    //     setTargetArticleInsObj(articleInstructionsData[e.target.id])
    //     setShowDeleteConfirmation(true)
    // }

    // const hideDeleteInstructionPopUp = (e) => {
    //     setTargetArticleInsObj([])
    //     setShowDeleteConfirmation(false)
    // }

    
    // const handleEditArticleIns = (e) =>{
    //     const {id} = e.target;
    //     var targetArticleObjToEdit = articleInstructionsData[id]
    //     if(!editTargetArticleInstruction){
    //         setTargetArticleInsObj(targetArticleObjToEdit)
    //     }else{
    //         setTargetArticleInsObj([])
    //     }

    //     toggleEditArticleInstruction()
    // }

    // const toggleEditArticleInstruction = () => {
    //     setEditTargetArticleInstruction(!editTargetArticleInstruction)
    //     setShowInstructionForm(!showInstructionForm)
    // }

    // const toggleAddArticleInstruction = () => {
    //     setEditTargetArticleInstruction(false)
    //     setShowInstructionForm(!showInstructionForm)
    // }



    // // display article in pop up or new page
    // const handleShowTargetArticleIns = () => {
    //     console.log("Handle delete")
    // }


    // const instructionsProps = {
    //     isCheck,
    //     handleClick,
    //     checkedNumber,

    //     showTargetInstructionBlog,

    //     handleEditArticleIns,
    //     showDeleteInstructionPopUp,

    //     // handleUpdateInstruction,
    //     // handleShowTargetArticleIns,
    // }

    // const article_instruction_form_props = {
    //     toggleAddArticleInstruction,
    //     toggleEditArticleInstruction,
    //     getinstructions,
    //     setTargetArticleInsObj,

    //     editTargetArticleInstruction,
    //     targetArticleInsObj,
    // }


    // const pagination_props = {
    //     pageNumber,
    //     numberOfPages,
    //     numberOfResultsAfterQuery,
    //     numberOfResults,
    //     numberOfTotalEntries
    // }

    return (
        <>
            {/* {showDeleteConfirmation && <DeleteConfirmation delete_title={names.delete_ins_title} onYes={handleDeleteInsOperation} onNo={hideDeleteInstructionPopUp}/>}
            {showCheckAnimation && <CheckAnimation/>}
            {showFailAnimation && <FailAnimation/>} */}

            <div className={classes.container}>
                <div className={classes.main}>
                    <div className={classes.main_top}>
                        <div className={classes.main_title}>
                            {names.articles} 
                            {/* {checkedNumber>0 ? (<div className={classes.items_selected_div}>{checkedNumber} {names.item_selected}</div>):(<div className={classes.items_selected_div}></div>)} */}
                        </div>

                        <div className={classes.main_operation}>
                            <div className={classes.custom_btn_img_text} onClick=''>
                                <img className={classes.action_button_each} src={add_white} alt='Add Instructions'/>
                                <div>Add</div>
                            </div>
                        </div>
                    </div>

                    {/* {showInstructionForm ? (
                         <ArticleInstructionForm {...article_instruction_form_props}/> 
                    ):( */}
                        <ResponsiveTable loadingResults={loadingResults}>
                            <>
                                {/* <thead><ArticlesTableHeaders headernames={article_table_headers} handleSelectAll={handleSelectAll} isCheckAll={isCheckAll}/></thead> */}
                                <thead><ArticlesTableHeaders/></thead>
                                <tbody className={classes.table_data_website}>
                                    {(articlesData.map((item, index) =>  <ArticlesTableItem />))}

                                    {articlesData.length === 0 && <div className={classes.no_data_found_div}>{names.no_articles_data_found}</div>} 
                                </tbody>
                            </>
                        </ResponsiveTable>
                    {/* )} */}

                    {/* pagination and alert section */}
                    <div className={classes.main_bottom}>
                        {/*  {...pagination_props} */}
                        <AmountSelect onChange={(e) => setNumberOfResults(e.target.value)}/> 
                        {/*  initial alert */}
                        {/* {showInstructionGetErrorMsg && <div className={classes.formAlertDiv}>Error in getting instrucitons data. Please reload/try again</div>} */}
                        
                        {/* delete alert msg */}
                        {/* {showInstructionDeleteSuccessMsg && <div>Successfully deleted target article instruction</div>} */}
                        {/* {showInstructionDeleteFailMsg && <div className={classes.formAlertDiv}>Target instruction deletion failed. Please try again</div>} */}

                        {numberOfPages >= 1 && <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} numberOfPages={numberOfPages}/>}
                    </div>
                </div>
            </div>
        </>

    )
}

export default Article;