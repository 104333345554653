import classes from './Logs.module.scss'

import AmountSelect from '../AmountSelect/AmountSelect';
import Pagination from '../Pagination/Pagination';
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable';

import LogsHeaders from './LogsHeaders';
import LogsTableItem from './LogsTableItem/LogsTableItem';

// import legend_classes from './LogsTableItem/LogsTableItem.module.scss'
// import TransferDetails from './TransferDetails/TransferDetails';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';


const Logs = () => {
    const names = useSelector(state => state.ui.language.values.logs)
    const api_token = useSelector(state => state.user.token)

    const [loadingResults, setLoadingResults] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    
    const [numberOfResults, setNumberOfResults] = useState(24)
    const [numberOfPages, setNumberOfPages] = useState(1)
    const [numberOfResultsAfterQuery, setNumberOfResultsAfterQuery] = useState(0)
    const [numberOfTotalEntries, setNumberOfTotalEntries] = useState(0)

    const [logsData, setLogsData] = useState([])
    const API_URL = process.env.REACT_APP_API_URL

    // const [showTransferDetails, setShowTransferDetails] = useState(false)
    // const [transferDetails, setTransferDetails] = useState([])

    const importLogsInitial = async () => {
        await importLogs()
    }

    useEffect(() => {
        importLogsInitial()
    },[pageNumber,numberOfResults])


    useEffect(() => {
        setPageNumber(1)
    },[numberOfResults])


    const importLogs = async () => {
        setLoadingResults(true)

        const response = await fetch(`${API_URL}/api/v1/logs`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                items: numberOfResults,
                page: pageNumber,
                category: ''
            })
        })


        let res_status = response.status
        const data = await response.json()
        if(res_status === 200) {
            console.log("Response in get logs :", data, res_status)
            
            const total_entires = data.total_logs_count
            setNumberOfTotalEntries(total_entires)
            setLogsData(data.res)
            setNumberOfResultsAfterQuery(data.logs_count)

            if(numberOfResults > total_entires){
                setNumberOfPages(1)
            }else{
                setNumberOfPages(Math.ceil(total_entires/numberOfResults))
            }
        }else{
            console.log("Error in getting logs data")
        }
        setLoadingResults(false)
    }

    // const toggleShowTransferDetails = () => {setShowTransferDetails(val => !val)}

    // const transferDetailsHandler = (data) => {
    //     setTransferDetails(data)
    //     setShowTransferDetails(true)
    // }

    return (
        <>
        {/* {showTransferDetails && <TransferDetails toggle={toggleShowTransferDetails} data={transferDetails}/>} */}
        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.main_top}>
                    <div className={classes.main_title}>
                        {names.title} 
                    </div>
                </div>


                <ResponsiveTable loadingResults={loadingResults}>
                    <thead>
                        <LogsHeaders names={names.headers}/>
                    </thead>
                    <tbody className={classes.table_data}>
                        {/* {Data.map((item,index) => <LogsTableItem data={item} key={index} transferDetailsHandler={() => transferDetailsHandler(item)}/>)} */}
                        {logsData.map((item,index) => <LogsTableItem data={item} key={index}/>)}
                        {logsData.length === 0 && <div className={classes.no_data_found_div}>{names.no_logs_data_found}</div>} 

                    </tbody>
                </ResponsiveTable>

                <div className={classes.main_bottom}>
                    <AmountSelect pageNumber={pageNumber} numberOfResultsAfterQuery={numberOfResultsAfterQuery} numberOfResults={numberOfResults} numberOfPages={numberOfPages} totalEntries={numberOfTotalEntries} onChange={(e) => setNumberOfResults(e.target.value)}/>
                    {numberOfPages >= 1 && <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} numberOfPages={numberOfPages}/>}
                </div>
            </div>
        </div>
        </>
    )

}

export default Logs;