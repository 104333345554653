const NAMES = {
    ENG: {
        sideBarAdmin:{
            dashboard:'Dashboard',
            articles:'Articles',
            article_instructions:'Instructions',
            logs:'Logs',
        },
        
        dashboardContent:{
            title:"Dashboard",
        },

        articlesContent: {
            articles : "Articles",
            no_articles_data_found: "No articles found", 
            article_table_headers: {
                domain: 'Domain'
            },
            
            add_new_article: {
                title: 'Add New Domain',
                domain_name: 'Domain Name',
                favicon: 'Favicon Icon',
                select_website_type: 'Select portal or website',
                cancel: 'Cancel',
                add: 'Add Domain'
            },
            
            pagination: {
                showing: 'Showing',
                rows: 'Items',
                page: 'Page',
                of: 'of',
                out_of:'out of',
            },
        },


        instructionsContent: {
            instructions: 'Instructions',
            item_selected: 'items selected',
            delete_ins_title: 'Are you sure to delete this instruction?',
            no_instruction_found: "No instruction found",

            instructions_table_headers:{
                domain: 'Domain',
                keywords: 'Keywords',
                wp_category: 'WP Category',
                title_instruction: 'Title Instruction',
                article_instruction: 'Article Instruction',
                image_instruction: 'Image Instruction',
                status:'Status',
                created_at: 'Created',
                updated_at: 'Updated',
                completed_at: 'Completed',
                actions: 'Actions',
            },

            instructions_table_placeholders:{
                domain: '(optional)',
                keywords: 'max 256 chars',
                wp_category: '(optional)',
                title_instruction: 'max 256 chars',
                article_instruction: 'max 512 chars',
                image_instruction: 'max 256 chars',
                status:'Status',
            },

            instruction_status: {
                active:"Active",
                inactive:"Inactive",
                
            },
          
            add_edit_instruction:{
                title: 'Add a new instruction',
                title_edit: 'Edit instruction',
                title_add: 'Add instruction',
                btn:'Add instruction',
                btn_edit:'Save instruction',
                target_websites:'Target Websites',
                status:'Status',
                add: 'Add',
                save: 'Save',
                cancel: 'Cancel',
    
            }
        },
        

        logs: {
            title: 'Logs',
            impersonate: 'Running',
            customer_create: 'Create',
            website_active: 'Add',
            website_others: 'Delete',
            on_air: 'Active',
            transfer: 'Inactive',
            delete_transfer: 'Complete',
            move_transfer: 'Interrupt',
            no_logs_data_found: "No logs found",

            headers: {
                date: 'Date',
                type: 'Type',
                responsible: 'Responsible',
                details: 'Details'
            },

            types: {
                website_status: 'Website status',
                transfer: 'New transfer',
                transfer_del: "Delete transfer",
                new_customer: "New customer",
                onair: 'On air',
                delete_onair: 'Delete On air',
                transfer_move: 'Move transfer',
                impersonate: 'Impersonate customer'
            },
            yesterday: 'Yesterday',
            today: 'Today'
        }
    }
}

export default NAMES;