
import classes from './CustomButton.module.scss'

/*
 * @param {String} color 
 * @param {String} name
 * @param {String} reverse
 * @param {boolean} disabled  
 * 
 * @returns JSX Element
 */
const CustomButton = (props) => {
    let style = [classes.container,classes.default,classes.blue]

    if(props.reverse) {

        if(props.color === 'green') style = [classes.container,classes.reverse,classes.reverse_green]
        if(props.color === 'red') style = [classes.container,classes.reverse,classes.reverse_red]
        if(props.color === 'blue') style = [classes.container,classes.reverse,classes.reverse_blue]
        if(props.color === 'orange') style = [classes.container,classes.reverse,classes.reverse_orange]
        
    }
    else {
        if(props.color === 'green') style = [classes.container,classes.default,classes.green]
        if(props.color === 'red') style = [classes.container,classes.default,classes.red]
        if(props.color === 'orange') style = [classes.container,classes.default,classes.orange]

    }

    if(props.disabled) {
        style = [classes.container,classes.reverse,classes.disabled]
    }

    return (
        <button className={style.join(' ')} onClick={props.onClick} type={props.type || 'button'} disabled={props.disabled}>
            {props.name}
        </button>
    )
}

export default CustomButton