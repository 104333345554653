import classes from './NavBar.module.scss'

import NavBarItem from '../NavBarItem/NavBarItem'
import arrow from '../../assets/icons/double_arrow_left.svg'
import dashboard from '../../assets/icons/dashboard.svg'
import website_control from '../../assets/icons/website_control.svg'
import logs from '../../assets/icons/logs.svg'
import subscribers from '../../assets/icons/customers.svg'
import payments from '../../assets/icons/payments.svg'
import summarize_white from '../../assets/icons/summarize_white.svg'
import automation from '../../assets/icons/automation.svg'
import logout from '../../assets/icons/logout.svg'


import IconContainerMedium from '../Customs/IconContainerMedium/IconContainerMedium';
import onair from '../../assets/icons/onair.svg'

import { useSelector, useDispatch } from 'react-redux'
import { uiActions } from '../../store/ui-slice'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';

const NavBar = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const NAMES = useSelector(state => state.ui.language.values)
    const sidebarAdminNames = NAMES.sideBarAdmin

    let isAdmin = useSelector(state => state.ui.showAdminInterface);
    let minimizeNavBar = useSelector(state => state.ui.minimizeNavBar)
    let width = window.screen.width

    useEffect(() => {
        if(width < 800 && width > 500) dispatch(uiActions.setMinimizeNavBar(true))

    })
    const minimizeToggle = () => {
        dispatch(uiActions.setMinimizeNavBar(!minimizeNavBar))
    }



    const logoutHandler = () => {
        localStorage.removeItem('access_token_article')
        localStorage.removeItem('role_article')
        dispatch(uiActions.setIsLoggedIn(false))

        if(isAdmin) navigate('/login/',{ replace: true })
        else navigate('/login',{ replace: true })
    }

    return (
        <>
        <div className={!minimizeNavBar ? classes.container : classes.container_minimized}>

            <div className={classes.logo_container} onClick={(minimizeNavBar && width > 800) ? minimizeToggle : () => {}}>
                {!minimizeNavBar && <div className={classes.logo}><p className={classes.logo_text}>ArticleGPT</p></div>}
                {!minimizeNavBar && <div className={classes.minimize}><img src={arrow} alt='<'/></div>}
                {minimizeNavBar && <div className={classes.small_logo}><img src={arrow} alt='<'/></div>}
            </div>

            <div className={classes.navbar_container}>
                <div className={classes.navbar_container_top}>
                    <NavBarItem image_src={payments} image_alt={sidebarAdminNames.article_instructions} name={sidebarAdminNames.article_instructions} route='instructions'/>
                    <NavBarItem image_src={summarize_white} image_alt={sidebarAdminNames.articles} name={sidebarAdminNames.articles} route='articles'/>
                    <NavBarItem image_src={logs} image_alt={sidebarAdminNames.logs} name={sidebarAdminNames.logs} route='logs'/>                   
                    
                    {/* <NavBarItem image_src={dashboard} image_alt={adminNames.dashboard} name={adminNames.dashboard} route='dashboard'/> */}
                    {/* <NavBarItem image_src={automation} image_alt={adminNames.automation} name={adminNames.automation} route='automation'/>
                    <NavBarItem image_src={subscribers} image_alt={adminNames.subsData} name={adminNames.subsData} route='subscribers'/>  */}
                    {/* <NavBarItem image_src={onair} image_alt={adminNames.onair} name={adminNames.onair} route='onair'/> */}
                    {/* <NavBarItem image_src={customers} image_alt={adminNames.managing} name={adminNames.managing} route='customers'/> */}
                </div>
                <div className={classes.navbar_container_bottom}>
                    <div className={classes.logout} onClick={logoutHandler}>
                        <IconContainerMedium color='red' image_src={logout} alt='logout'/>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default NavBar;