
import NavBar from "../NavBar/NavBar"
import Article from "../Article/Article"
import ArticleInstruction from "../ArticleInstruction/ArticleInstruction"
import Logs from '../Logs/Logs'

import { useParams } from 'react-router-dom'
import { Navigate } from "react-router-dom"

import useWindowSize from '../../assets/hooks/useWindowSize.js'
// import { useSelector } from "react-redux"

const Main = () => {
    const params = useParams()
    const page = params.page
    let {width} = useWindowSize()
    const validRoutes = ['articles', 'instructions', 'logs']
    // const showAdminInterface = useSelector(state => state.ui.showAdminInterface)

    return (
        <>
            {width > 500 && <NavBar/>}
            {(page === 'articles'  && <Article />)}
            {(page === 'instructions'  && <ArticleInstruction />)}
            {(page === 'logs' && <Logs />)}
            
            {!validRoutes.includes(page) && <Navigate to='/'/>}
        </>
    )
}
export default Main