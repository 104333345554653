import classes from './LoginLoad.module.scss'
import LoadingTable from '../LoadingTable/LoadingTable'

const LoginLoad = () => {
    return (
        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.top_container}>
                    <div className={classes.logo}>
                        <p className={classes.logo_text}>Article GPT</p>
                    </div>
                </div>
                <LoadingTable />
            </div>
        </div>
    )
}

export default LoginLoad;