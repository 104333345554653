
const ArticleInstructionTableHeaders = (props) => {
    let headers_names = props.headernames

    return (
        <tr>
            <th><input id='selectAll' type="checkbox" name='selectAll' checked={props.isCheckAll} onChange={props.handleSelectAll}/><p>Select All</p></th>
            
            <th>{headers_names.domain}</th>
            <th>{headers_names.wp_category}</th>
            <th>{headers_names.title_instruction}</th>
            <th>{headers_names.image_instruction}</th>
            <th>{headers_names.article_instruction}</th>
            <th>{headers_names.status}</th>
            <th>{headers_names.created_at}</th>
            <th>{headers_names.updated_at}</th>
            <th>{headers_names.completed_at}</th>
            <th>{headers_names.actions}</th>
    </tr>
    )
}
export default ArticleInstructionTableHeaders