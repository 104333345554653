import './App.css';


// components
import Main from './components/Main/Main';
import Article from './components/Article/Article';
import Login from './components/Login/Login';
import LoginLoad from './components/LoginLoad/LoginLoad';


// assets
import bg_primary from './assets/images/bg_primary.jpg'

// react modules
import {Route , Routes, Navigate } from 'react-router-dom'
import {useEffect, useState, useRef} from 'react'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { userActions } from './store/user-slice';
import { uiActions } from './store/ui-slice';
import { TimeOutHandler } from './utils/helper_functions';
import useWindowSize from './assets/hooks/useWindowSize.js'


// external moduels

//  openai_gpt_app
// articlegpt_backend




// App component
function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate ()
  const initPath = useRef(window.location.pathname)
  const isLogged = useSelector(state => state.ui.isLoggedIn) 
  const [loginProcess, setLoginProcess] = useState(false)
  
  // const [triedMultipleTimes, setTriedMultipleTimes] = useState(false)
  // const [triedTimes, setTriedTimes] = useState(0)

  const API_URL = process.env.REACT_APP_API_URL
  // const PATH_NAME = window.location.pathname
  let {width} = useWindowSize()
  
  // delete section
  // useEffect(()=>{
  //   dispatch(uiActions.setIsLoggedIn(true))
  // }, [])


  useEffect(() => {
    let token = localStorage.getItem('access_token_article')
    let role = localStorage.getItem('role_article')   
        
    if(token && role) {
        console.log("triggered")
        console.log("\n app.js : inside useeffect : token and role availbale and isLogged is: ", isLogged, token, role)
         setLoginProcess(true)
         LoginHandler(token, role)
      }
  },[isLogged])


  const LoginHandler = (token, role) => {    
    if(['ADMIN','MODERATOR'].includes(role)){
        let REQ_PATH =`${API_URL}/api/v1/employee/data`
        fetch(REQ_PATH, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`},
        })
        .then(val => {
            // Case user edited the values in the localStorage
            var val_status = val.status
            console.log("val status is :", val_status)

            if(val_status !== 200) {
              if(val_status === 400){
                  localStorage.removeItem('access_token_article')
                  localStorage.removeItem('role_article')
                  navigate('/login',{replace:true})
                }
                else if(val_status===500){
                  console.log("Internal app error. Contact admin :", val_status)
                }
                else{
                  console.log("Network or other issues occurred :", val_status)
                }

              console.log("Error in login using previous credentials. Try again")
              // dispatch(uiActions.setIsLoggedIn(false))
              navigate('/login', {replace:true})
              setLoginProcess(false)
            }
            // Case all values are correct
            else{
              return val.json()
          }
        })
        .then(data => {
            var data = data['res']  
            dispatch(userActions.setUser(data['email']))
            dispatch(userActions.setIsAdmin(['ADMIN'].includes(data['role'])))
            dispatch(userActions.setIsModerator(['ADMIN','MODERATOR'].includes(data['role'])))
            dispatch(userActions.setToken(data['access_token']))
            dispatch(uiActions.setIsLoggedIn(true))

            if(!initPath.current.includes('login')) {
              navigate(initPath.current,{ replace: true })
              TimeOutHandler(() => {setLoginProcess(false)})
            }
            else {
              navigate('/',{ replace: true })
              TimeOutHandler(() => {setLoginProcess(false)})
            }
        }).catch(error=> {
          console.log("Error")
          dispatch(uiActions.setIsLoggedIn(false))
          setLoginProcess(false)
        })
    }
  }


  return (
    <div className="app">
      {loginProcess && <LoginLoad/>}
      {width < 500 ? <div>Please open the app in desktop</div>: 
        // will work on this section to create separate section for loggedin and not loggedin user
          <Routes>
            {isLogged?(
              <>
                <Route path='/:page' element={<Main />}/>
                <Route path='/' exact element={<Navigate to='/instructions'/>}/>
              </>
            ):(
              <>
                <Route path='/' exact element={<Login setLoginProcess={setLoginProcess}/>}/>
                <Route path='/login' exact element={<Login setLoginProcess={setLoginProcess}/>}/>
                <Route path='/:page' exact element={<Navigate to='/'/>}/>

                {/* {triedMultipleTimes?(
                  <Route path='/:any' exact element={
                    <div className='notFound'>
                      <p className='notFoundText'>
                        404 Not Found
                      </p>
                    </div>}/>
                ):(
                  <>
                    <Route path='/' exact element={<Navigate to='/'/>}/>
                  </>)} */}
              </>
            )}
          </Routes>
      }
    </div>
  )
}



export default App;
