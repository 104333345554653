import classes from './ArticleInstructionForm.module.scss'

import CustomButton from '../../Customs/CustomButton/CustomButton'
import CustomSelect from '../../Customs/CustomSelect/CustomSelect'

import CheckAnimation from '../../CheckAnimation/CheckAnimation'
import FailAnimation from '../../FailAnimation/FailAnimation'
import PopUpCard from '../../Cards/PopUpCard/PopUpCard'

import {useSelector} from 'react-redux'
import { useEffect, useState } from 'react'
import { TimeOutHandler } from '../../../utils/helper_functions';

import LoadingTable from '../../LoadingTable/LoadingTable'







const ArticleInstructionForm = (props) => {
    const API_URL = process.env.REACT_APP_API_URL
    const api_token = useSelector(state => state.user.token)

    const targetArticleInsObj = props.targetArticleInsObj
    const editInsObj = props.editTargetArticleInstruction
    const toggleAddArticleInstruction = props.toggleAddArticleInstruction
    const toggleEditArticleInstruction = props.toggleEditArticleInstruction
    const getinstructions = props.getinstructions
    const setTargetArticleInsObj = props.setTargetArticleInsObj

    const names = useSelector(state => state.ui.language.values.instructionsContent.add_edit_instruction)
    const labels = useSelector(state => state.ui.language.values.instructionsContent.instructions_table_headers)
    const ins_status = useSelector(state => state.ui.language.values.instructionsContent.instruction_status)

    // variable states
    const [newDomain, setNewDomain] = useState('')
    const [newKeywords, setNewKeywords] = useState('')
    const [newWp, setNewWp] = useState('')
    const [newTitleIns, setNewTitleIns] = useState('')
    const [newImageIns, setNewImageIns] = useState('')
    const [newArticleIns, setNewArticleIns] = useState('')
    const [newStatus, setNewStatus] = useState('')
    

    // animation states
    const [loadingResults, setLoadingResults] = useState(false)
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)
   
    // alert states
    const [showFormSuccessAlert, setShowFormSuccessAlert] = useState(false)
    const [showFormFailAlert, setShowFormFailAlert] = useState(false)
    const [showFormAlert, setShowFormAlert] = useState(false)

    const [showFormAlertSuccessMsg, setShowFormAlertSuccessMsg] = useState('')
    const [showFormAlertMsg, setShowFormAlertMsg] = useState('')




    const inputInitialState = () => {
        setNewDomain('')
        setNewWp('')
        setNewKeywords('')
        setNewTitleIns('')
        setNewImageIns('')
        setNewArticleIns('')
        setNewStatus('')
    }

    const handleAddEditArticleIns = async () => {
        var target_method;
        var target_body = {}
        if(editInsObj){
            console.log("\n\nEditing instructions to backend :", targetArticleInsObj) 
            target_method = "PUT"  
            target_body['id'] = targetArticleInsObj['id']
     
        }else{
            console.log("\n\nAdding instructions to backend :", )    
            target_method = "POST"   
        }

        target_body['domain'] = newDomain
        target_body['wp_category'] = newWp
        target_body['keywords'] = newKeywords
        target_body['title_instruction'] = newTitleIns
        target_body['image_instruction'] = newImageIns
        target_body['article_instruction'] = newArticleIns
        target_body['status'] = newStatus
        console.log("POST/PUT :", editInsObj, target_body)

        setLoadingResults(true)
        const response = await fetch(`${API_URL}/api/v1/articleinstruction`, {
            method: target_method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`
            },
            body: JSON.stringify(target_body)
        })

        const res_status = response.status
        const data = await response.json()

        setLoadingResults(false)
        if(res_status === 200) {
            setShowFormSuccessAlert(true)
            setShowCheckAnimation(true)
            TimeOutHandler(()=>{
                setShowCheckAnimation(false)
                getinstructions()                
            }, 1500)
            
            TimeOutHandler(()=>{
                setShowFormSuccessAlert(false)

                //going back to input initial state and closing pop up
                inputInitialState() 
                setTargetArticleInsObj([])
                if(editInsObj){
                    toggleEditArticleInstruction()
                }else{
                    toggleAddArticleInstruction()
                }
            }, 2500)
        }
        else{
            console.log("=== Error in get instructions ===> ", res_status, data.success, data.error)
            setShowFormFailAlert(true)
            setShowFailAnimation(true)

            TimeOutHandler(()=>{
                setShowFailAnimation(false)
            }, 1500)

            TimeOutHandler(()=>{
                setShowFormFailAlert(false)
            }, 2500)
        }
    }


    useEffect(()=>{
        if(editInsObj){
            // setNewDomain(targetArticleInsObj['domain'])
            // setNewWp(targetArticleInsObj['wp_category'])
            // setNewKeywords(targetArticleInsObj['keywords'])
            // setNewTitleIns(targetArticleInsObj['title_instruction'])
            // setNewImageIns(targetArticleInsObj['image_instruction'])
            // setNewArticleIns(targetArticleInsObj['article_instruction'])
            setNewStatus(targetArticleInsObj['status'])
        }else{
            setNewStatus('ACTIVE')
        }

    }, [])

    return (
        <PopUpCard toggle={editInsObj?toggleEditArticleInstruction:toggleAddArticleInstruction} header={editInsObj?names.title_edit:names.title_add}>
            {showCheckAnimation && <CheckAnimation />}
            {showFailAnimation && <FailAnimation />}
            {loadingResults && <LoadingTable />}

           <>
                {editInsObj? (
                        <>
                            <div className={classes.creative_info_div}>
                                <label className={classes.middle_item_label}>{names.editCreativeInfo}</label>
                                <div className={classes.creative_info_div_items}>
                                    <b>Domain</b> : <b>{targetArticleInsObj.domain}</b><br/>
                                    <b>WP Category</b> : {targetArticleInsObj.wp_category}<br/>
                                    <b>Title Instruction</b> : {targetArticleInsObj.title_instruction}<br/>
                                    <b>Image Instruction</b> : {targetArticleInsObj.image_instruction}<br/>
                                    <b>Article Instruction</b> : {targetArticleInsObj.article_instruction}<br/>
                                    <b>Status</b> : <b>{targetArticleInsObj.status}</b><br/>
                                </div>
                            </div>
                        </>
                    ):(    
                        <></>            
                    )}


                    {/* /////////////////////////////////// add section //////////////////////////////////////// */}
                    <form className={classes.main_middle}>
                        <div className={classes.middle_item}> 
                            <div className={classes.middle_item_flex_column}>
                                <label className={classes.middle_item_label_type_one}>{labels.domain}</label>
                                <div className={classes.middle_item_input}>
                                    <input placeholder={newDomain}  onChange={(e)=> setNewDomain(e.target.value)}/>
                                </div>
                            </div>
                            <div className={classes.middle_item_flex_column}>
                                <label className={classes.middle_item_label_type_one}>{labels.wp_category}</label>
                                <div className={classes.middle_item_input}>
                                    <input placeholder={newWp}  onChange={(e)=> setNewWp(e.target.value)}/>
                                </div>
                            </div>
                        </div>

                        <div className={classes.middle_item}> 
                            <div className={classes.middle_item_flex_column}>
                                <label className={classes.middle_item_label_type_one}>{labels.keywords}</label>
                                <div className={classes.middle_item_input}>
                                    <input placeholder={newKeywords}  onChange={(e)=> setNewKeywords(e.target.value)} required/>
                                </div>
                            </div>

                            <div className={classes.middle_item_flex_column_select}>
                                <label className={classes.middle_item_label_type_one}>{labels.status}</label>
                                <div className={classes.middle_item_input}>
                                        <CustomSelect onChange={(e)=> setNewStatus(e.target.value)} value={newStatus} required>
                                            <option value=''></option>
                                            <option value='ACTIVE'>{ins_status.active}</option>
                                            <option value='INACTIVE'>{ins_status.inactive}</option>
                                        </CustomSelect>
                                </div>
                            </div>
                        </div>

                        <div className={classes.middle_item}> 
                            <div className={classes.middle_item_flex_column}>
                                <label className={classes.middle_item_label_type_one}>{labels.title_instruction}</label>
                                <div className={classes.middle_item_input}>
                                    <input placeholder={newTitleIns}  onChange={(e)=> setNewTitleIns(e.target.value)} required/>
                                </div>
                            </div>
                            <div className={classes.middle_item_flex_column}>
                                <label className={classes.middle_item_label_type_one}>{labels.image_instruction}</label>
                                <div className={classes.middle_item_input}>
                                    <input placeholder={newImageIns}  onChange={(e)=> setNewImageIns(e.target.value)} required/>
                                </div>
                            </div>
                        </div>
                        <div className={classes.middle_item}> 

                            <div className={classes.middle_item_flex_column}>
                                <label className={classes.middle_item_label_type_one}>{labels.article_instruction}</label>
                                <div className={classes.middle_item_input}>
                                    <input placeholder={newArticleIns}  onChange={(e)=> setNewArticleIns(e.target.value)} required/>
                                </div>
                            </div>
                        </div>
                    </form>
                    
                {/* buttons at bottom */}
                <div className={classes.buttons}>
                    {showFormAlert && <div className={classes.formAlertDiv}>Already a creative exits with given automation time</div>}
                    
                    {/* edit/add success/failed */}
                    {showFormSuccessAlert && <div>{editInsObj?'Successfully updated target instruction':'Successfully added new instruction'}</div>}
                    {showFormFailAlert && <div className={classes.formAlertDiv}>{editInsObj?'Failed edit operation.':'Add new instruction failed.'} Please try again with valid values</div>}
                    

                    <div className={classes.cancel} onClick={editInsObj?toggleEditArticleInstruction:toggleAddArticleInstruction}>{names.cancel}</div>
                    <div className={classes.customButton}>
                        <CustomButton reverse color='red' name={editInsObj?names.save:names.add} onClick={handleAddEditArticleIns}/>
                    </div>
                </div>
            </>
        </PopUpCard>
    )
}

export default ArticleInstructionForm;