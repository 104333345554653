import classes from '../../Creatives/Creatives.module.scss';

import pencil from '../../../assets/icons/pencil.svg'
import pencil_gray from '../../../assets/icons/pencil_gray.svg'

import delete_red from '../../../assets/icons/delete_red.svg'
import delete_gray from '../../../assets/icons/delete_gray.svg'

import logs_blue from '../../../assets/icons/logs_blue.svg'

// import clock_blue from '../../../assets/icons/clock_blue.svg'
// import informaton_red from '../../../assets/icons/information.svg'

// import approved from '../../../assets/icons/approved.svg'
// import disapproved from '../../../assets/icons/disapproved.svg'
// import reset from '../../../assets/icons/reset.svg'




const ArticleInstructionTableItem = (props) => {
    const data = props.data
    const index = props.index
    const isCheckList = props.isCheck
    const showTargetInstructionBlog = props.showTargetInstructionBlog
    const handleEditArticleIns = props.handleEditArticleIns
    const showDeleteInstructionPopUp = props.showDeleteInstructionPopUp



    const website_url = "https://"+data.domain

    return (
        <tr>
            <td><input  id={index} onChange={props.handleClick} type="checkbox" name={data.domain.toString()} checked={isCheckList.includes(index)}/></td>

            <td> <a href={website_url} target='_blank' rel="noreferrer">{data.domain}</a> </td>
            <td>{data.wp_category}</td>
            <td>{data.title_instruction}</td>
            <td>{data.image_instruction}</td>
            <td>{data.article_instruction}</td>
            <td>{data.status}</td>
            <td>{data.created_at}</td>
            <td>{data.updated_at}</td>
            <td>{data.completed_at}</td>


            <td>
            <img className={classes.action_button_each_website} src={logs_blue} alt='Show Funnel Creatives'  id={index} onClick={showTargetInstructionBlog}/>
                {props.checkedNumber===0?
                (
                    <>
                        <img className={classes.action_button_each} src={isCheckList.includes(index)?pencil_gray:pencil} alt='Edit' id={index} onClick={handleEditArticleIns}/>
                        <img className={classes.action_button_each_website} src={isCheckList.includes(index)?delete_gray:delete_red} alt='Delete'  id={index} onClick={showDeleteInstructionPopUp}/>
                    </>
                ):(
                    <>
                        <img className={classes.action_button_each} src={pencil_gray} alt='Delete' id={index}/>
                        <img className={classes.action_button_each_website} src={delete_gray} alt='Edit' id={index}/>
                    </>
                )}

            </td>
        </tr>
    )
}

export default ArticleInstructionTableItem;

